import React from "react"
import Layout from "../components/layout"
import {
  Accordion,
  Tab,
  Nav,
  Card,
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap"
import SearchFaq from "../components/SearchFaqContainer"
import withLocation from "../components/withLocation"
import UrlService from "../services/global/urlService"
import { Link } from "gatsby"

class ListFAQTemplate extends React.Component {
  data
  frequentlyAskedQuestions
  membership
  operation
  refund
  services
  lifeOfContract
  regulation
  active
  allFaq
  search

  constructor(props) {
    super(props)
    this.search = props.search
    this.state = {
      iconClass: "faqAccordionSectionIcon fas fa-plus-circle",
      selectTitle: this.props.location.state ? this.props.location.state.selectTitle : "Questions fréquentes",
      category: this.props.location.state ? this.props.location.state.category : "frequentlyAskedQuestions"
    }
    this.getMetas = this.getMetas.bind(this)
  }

  selectTitle(title) {
    console.log(title)
    this.setState({ selectTitle: title })
  }

  changeIconClass(id) {
    this.setState({ iconClass: id })
    if (this.state.iconClass === id) {
      this.setState({ iconClass: null })
    }
  }

  getMetas() {
    const { search } = this.props
    const { recherche } = search || {}
    let metas = {
      title: "Foire aux questions",
      description:
        "Vous avez des interrogations concernant le contrat, les remboursements, les garanties, le fonctionnement de la mutuelle santé ? Consultez notre FAQ !",
      keywords: [],
    }
    if (!!recherche) {
      metas.title = `Recherche "${recherche}"`
      metas.keywords.push(recherche)
    }
    if (recherche === "garantie") {
      metas.description =
        "Vous recherchez des informations sur votre garantie complémentaire santé ? Consultez la foire aux questions !"
    } else if (recherche === "adhésion") {
      metas.description =
        "Vous recherchez des informations sur l'adhésion à une complémentaire santé ? Consultez notre foire aux questions !"
    } else if (recherche === "remboursement") {
      metas.description =
        "Vous recherchez des informations sur les remboursements de votre mutuelle santé ? Consultez la foire aux questions !"
    } else if (recherche === "contrat") {
      metas.description =
        "Vous recherchez des informations sur votre contrat de mutuelle santé ? Consultez la foire aux questions !"
    }
    return metas
  }

  render() {
    this.data = this.props.pageContext
    this.frequentlyAskedQuestions = this.data.frequentlyAskedQuestions
    this.membership = this.data.membership
    this.operation = this.data.operation
    this.refund = this.data.refund
    this.services = this.data.services
    this.lifeOfContract = this.data.lifeOfContract
    this.allFaq = this.data.allFaq
    this.regulation = this.data.regulation
    if (this.props.search.recherche) {
      this.search = this.props.search
    }

    return (
      <Layout
        titleSeo={this.getMetas().title}
        description={this.getMetas().description}
        keywordsSeo={this.getMetas().keywords}
      >
        <section className="pageHeader">
          <div className="pageHeaderContainer">
            <nav>
              <ul className="breadcrumb">
                <li className="breadcrumbItem">
                  <a
                    href="/"
                    className="breadcrumbItemLink breadcrumbItemLinkHome"
                  >
                    <i className="icon-mgc" />
                  </a>
                </li>
                <li className="breadcrumbItem">
                  <span className="breadcrumbItemLink breadcrumbItemLinkMiddle">
                    FAQ
                  </span>
                </li>
              </ul>
            </nav>
            <div className="ciblePubDesktop" />
          </div>
        </section>

        <section className="pageHeader callback">
          <div>
            <h1 className="pageHeaderTitle pageHeaderTitle-with-text">
              Foire aux questions
            </h1>
          </div>
          <div>
            <p className="pageHeaderSubtitle">
              Vous avez des interrogations sur l’adhésion, le contrat, les
              remboursements, les garanties ou d’autres éléments liés au
              fonctionnement de la complémentaire santé à la mutuelle MGC ?
            </p>
            <SearchFaq allFaq={this.allFaq} search={this.search} />
          </div>
        </section>

        <div className="container">
          <section className="faq">
            <div className="">
              <Tab.Container
                id="uncontrolled-tab-example"
                onSelect={(e) => this.setState({ category: e })}
                activeKey={this.state.category}
              >
                <div className="row">
                  <ButtonGroup
                    className="faqCategoriesSelect col-8 offset-2"
                    id="mySelect"
                  >
                    <DropdownButton
                      as={ButtonGroup}
                      title={this.state.selectTitle}
                      size="lg"
                      block="true"
                      vertical="true"
                    >
                      <Dropdown.Item
                        eventKey="frequentlyAskedQuestions"
                        onClick={() => this.selectTitle("Questions fréquentes")}
                      >
                        <span>Questions fréquentes</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="membership"
                        onClick={() => this.selectTitle("Adhésion")}
                      >
                        <span>Adhésion</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="operation"
                        onClick={() => this.selectTitle("Fonctionnement")}
                      >
                        <span>Fonctionnement</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="refund"
                        onClick={() => this.selectTitle("Remboursement")}
                      >
                        <span>Remboursement</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="services"
                        onClick={() => this.selectTitle("Services")}
                      >
                        <span>Services</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="lifeOfContract"
                        onClick={() => this.selectTitle("Vie du contrat")}
                      >
                        <span>Vie du contrat</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="regulation"
                        onClick={() => this.selectTitle("Règlementation")}
                      >
                        <span>Règlementation</span>
                      </Dropdown.Item>
                    </DropdownButton>
                  </ButtonGroup>
                  <div className="col-md-4 faqCategories">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link
                          className="nav-link faqCategoriesListItem"
                          eventKey="frequentlyAskedQuestions"
                        >
                          Questions fréquentes
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className="nav-link faqCategoriesListItem"
                          eventKey="membership"
                        >
                          Adhésion
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className="nav-link faqCategoriesListItem"
                          eventKey="operation"
                        >
                          Fonctionnement
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className="nav-link faqCategoriesListItem"
                          eventKey="refund"
                        >
                          Remboursement
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className="nav-link faqCategoriesListItem"
                          eventKey="services"
                        >
                          Services
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className="nav-link faqCategoriesListItem"
                          eventKey="lifeOfContract"
                        >
                          Vie du contrat
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className="nav-link faqCategoriesListItem"
                          eventKey="regulation"
                        >
                          Règlementation
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="col-xs-12 col-md-8 faqQuestions">
                    <Tab.Content>
                      <Tab.Pane eventKey="frequentlyAskedQuestions">
                        <div className="faqAccordion" id="accordionQuestions">
                          <h3 className="faqAccordionTitle">
                            Questions fréquentes
                          </h3>
                          <Accordion>
                            {this.frequentlyAskedQuestions != null &&
                              this.frequentlyAskedQuestions.map(q => (
                                <Card key={q.id}>
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey={q.id}
                                  >
                                    <div
                                      as={Button}
                                      variant="link"
                                      className="faqAccordionSectionTitle"
                                      onClick={() => this.changeIconClass(q.id)}
                                    >
                                      {q.title}
                                      <i
                                        className={
                                          this.state.iconClass === q.id
                                            ? "faqAccordionSectionIcon fas fa-minus-circle"
                                            : "faqAccordionSectionIcon fas fa-plus-circle"
                                        }
                                      />
                                    </div>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey={q.id}>
                                    <Card.Body>
                                      {q.description}
                                      <Link
                                        to={`/faq/${UrlService.addSlashToEndOfUrl(
                                          q.url
                                        )}`}
                                        className="faqAccordionSectionArticleLink"
                                        state={{
                                          selectTitle: this.state.selectTitle,
                                          category: this.state.category
                                        }}
                                      >
                                        En savoir plus
                                      </Link>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              ))}
                          </Accordion>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="membership">
                        <div className="faqAccordion" id="accordionQuestions">
                          <h3 className="faqAccordionTitle">Adhésion</h3>
                          <Accordion>
                            {this.membership != null &&
                              this.membership.map(q => (
                                <Card key={q.id}>
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey={q.id}
                                  >
                                    <div
                                      as={Button}
                                      variant="link"
                                      className="faqAccordionSectionTitle"
                                      onClick={() => this.changeIconClass(q.id)}
                                    >
                                      {q.title}
                                      <i
                                        className={
                                          this.state.iconClass === q.id
                                            ? "faqAccordionSectionIcon fas fa-minus-circle"
                                            : "faqAccordionSectionIcon fas fa-plus-circle"
                                        }
                                      />
                                    </div>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey={q.id}>
                                    <Card.Body>
                                      {q.description}
                                      <Link
                                        to={`/faq/${UrlService.addSlashToEndOfUrl(
                                          q.url
                                        )}`}
                                        className="faqAccordionSectionArticleLink"
                                        state={{
                                          selectTitle: this.state.selectTitle,
                                          category: this.state.category
                                        }}
                                      >
                                        En savoir plus
                                      </Link>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              ))}
                          </Accordion>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="operation">
                        <div className="faqAccordion" id="accordionQuestions">
                          <h3 className="faqAccordionTitle">Fonctionnement</h3>
                          <Accordion>
                            {this.operation != null &&
                              this.operation.map(q => (
                                <Card key={q.id}>
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey={q.id}
                                  >
                                    <div
                                      as={Button}
                                      variant="link"
                                      className="faqAccordionSectionTitle"
                                      onClick={() => this.changeIconClass(q.id)}
                                    >
                                      {q.title}
                                      <i
                                        className={
                                          this.state.iconClass === q.id
                                            ? "faqAccordionSectionIcon fas fa-minus-circle"
                                            : "faqAccordionSectionIcon fas fa-plus-circle"
                                        }
                                      />
                                    </div>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey={q.id}>
                                    <Card.Body>
                                      {q.description}
                                      <Link
                                        to={`/faq/${UrlService.addSlashToEndOfUrl(
                                          q.url
                                        )}`}
                                        className="faqAccordionSectionArticleLink"
                                        state={{
                                          selectTitle: this.state.selectTitle,
                                          category: this.state.category
                                        }}
                                      >
                                        En savoir plus
                                      </Link>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              ))}
                          </Accordion>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="refund">
                        <div className="faqAccordion" id="accordionQuestions">
                          <h3 className="faqAccordionTitle">Remboursement</h3>
                          <Accordion>
                            {this.refund != null &&
                              this.refund.map(q => (
                                <Card key={q.id}>
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey={q.id}
                                  >
                                    <div
                                      as={Button}
                                      variant="link"
                                      className="faqAccordionSectionTitle"
                                      onClick={() => this.changeIconClass(q.id)}
                                    >
                                      {q.title}
                                      <i
                                        className={
                                          this.state.iconClass === q.id
                                            ? "faqAccordionSectionIcon fas fa-minus-circle"
                                            : "faqAccordionSectionIcon fas fa-plus-circle"
                                        }
                                      />
                                    </div>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey={q.id}>
                                    <Card.Body>
                                      {q.description}
                                      <Link
                                        to={`/faq/${UrlService.addSlashToEndOfUrl(
                                          q.url
                                        )}`}
                                        className="faqAccordionSectionArticleLink"
                                        state={{
                                          selectTitle: this.state.selectTitle,
                                          category: this.state.category
                                        }}
                                      >
                                        En savoir plus
                                      </Link>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              ))}
                          </Accordion>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="services">
                        <div className="faqAccordion" id="accordionQuestions">
                          <h3 className="faqAccordionTitle">Services</h3>
                          <Accordion>
                            {this.services != null &&
                              this.services.map(q => (
                                <Card key={q.id}>
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey={q.id}
                                  >
                                    <div
                                      as={Button}
                                      variant="link"
                                      className="faqAccordionSectionTitle"
                                      onClick={() => this.changeIconClass(q.id)}
                                    >
                                      {q.title}
                                      <i
                                        className={
                                          this.state.iconClass === q.id
                                            ? "faqAccordionSectionIcon fas fa-minus-circle"
                                            : "faqAccordionSectionIcon fas fa-plus-circle"
                                        }
                                      />
                                    </div>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey={q.id}>
                                    <Card.Body>
                                      {q.description}
                                      <Link
                                        to={`/faq/${UrlService.addSlashToEndOfUrl(
                                          q.url
                                        )}`}
                                        className="faqAccordionSectionArticleLink"
                                        state={{
                                          selectTitle: this.state.selectTitle,
                                          category: this.state.category
                                        }}
                                      >
                                        En savoir plus
                                      </Link>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              ))}
                          </Accordion>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="lifeOfContract">
                        <div className="faqAccordion" id="accordionQuestions">
                          <h3 className="faqAccordionTitle">Vie du contrat</h3>
                          <Accordion>
                            {this.lifeOfContract != null &&
                              this.lifeOfContract.map(q => (
                                <Card key={q.id}>
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey={q.id}
                                  >
                                    <div
                                      as={Button}
                                      variant="link"
                                      className="faqAccordionSectionTitle"
                                      onClick={() => this.changeIconClass(q.id)}
                                    >
                                      {q.title}
                                      <i
                                        className={
                                          this.state.iconClass === q.id
                                            ? "faqAccordionSectionIcon fas fa-minus-circle"
                                            : "faqAccordionSectionIcon fas fa-plus-circle"
                                        }
                                      />
                                    </div>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey={q.id}>
                                    <Card.Body>
                                      {q.description}
                                      <Link
                                        to={`/faq/${UrlService.addSlashToEndOfUrl(
                                          q.url
                                        )}`}
                                        className="faqAccordionSectionArticleLink"
                                        state={{
                                          selectTitle: this.state.selectTitle,
                                          category: this.state.category
                                        }}
                                      >
                                        En savoir plus
                                      </Link>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              ))}
                          </Accordion>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="regulation">
                        <div className="faqAccordion" id="accordionQuestions">
                          <h3 className="faqAccordionTitle">Règlementation</h3>
                          <Accordion>
                            {this.regulation != null &&
                              this.regulation.map(q => (
                                <Card key={q.id}>
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey={q.id}
                                  >
                                    <div
                                      as={Button}
                                      variant="link"
                                      className="faqAccordionSectionTitle"
                                      onClick={() => this.changeIconClass(q.id)}
                                    >
                                      {q.title}
                                      <i
                                        className={
                                          this.state.iconClass === q.id
                                            ? "faqAccordionSectionIcon fas fa-minus-circle"
                                            : "faqAccordionSectionIcon fas fa-plus-circle"
                                        }
                                      />
                                    </div>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey={q.id}>
                                    <Card.Body>
                                      {q.description}
                                      <Link
                                        to={`/faq/${UrlService.addSlashToEndOfUrl(
                                          q.url
                                        )}`}
                                        className="faqAccordionSectionArticleLink"
                                        state={{
                                          selectTitle: this.state.selectTitle,
                                          category: this.state.category
                                        }}
                                      >
                                        En savoir plus
                                      </Link>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              ))}
                          </Accordion>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}
export default withLocation(ListFAQTemplate)
